import React, { useEffect, useState } from "react";
// import { clearPermissions } from "../../Redux/app/action";
import AllowLocationFallBack from "../../Assets/Location_permission.svg";
import AllowNotificationFallBack from "../../Assets/Notification_permission.svg";
import DisableBatteryOptimization from "../../Assets/disable_battery_optimization.gif";
import { connect } from "react-redux";
import Button from "../../Components/Button/Button";
import { setErrorStatus } from "../../Redux/status/action";
import { setPermissionStatus } from "../../Redux/app/action";

function Permissions(props) {
  const [permissionData, setPermissionData] = useState(null);
  const values = {
    Location: {
      dataCy: "location-permission-screen",
      title: "Allow Location Permission",
      description: "Enables us to monitor your real-time location",
      images: AllowLocationFallBack,
      onclick: () => {
        window.location.href =
          "gzero://tracking-permission?location-permission=true";
      },
    },
    Notification: {
      dataCy: "notification-permission-screen",
      title: "Allow Notification Permission",
      description: "Enables the app to run in background",
      images: AllowNotificationFallBack,
      onclick: () => {
        window.location.href =
          "gzero://tracking-permission?notification-permission=true";
      },
    },
    DisableBatteryOptimization: {
      dataCy: "disable-battery-optimization-screen",
      title: "Disable Battery Optimization",
      description: "Enables the app to run longer in background",
      images: DisableBatteryOptimization,
      onclick: () => {
        window.location.href =
          "gzero://tracking-permission?disable-batteryOptimization=true";
      },
    },
  };

  useEffect(() => {
    setPermissionData(props.app.data?.permissions);
    // eslint-disable-next-line
  }, [props.app.data]);

  useEffect(() => {
    if (
      window.location.search ===
      "?location-permission=approved&notification-permission=approved&disable-batteryOptimization=disabled"
    ) {
      props.setPermissionStatus({
        location: true,
        notification: true,
        disableBatterOptimization: true,
      });
      props.navigate("/");
    }
    // eslint-disable-next-line
  }, [window.location]);

  const showPermissionStep = (data) => {
    if (!data?.location) {
      return "Location";
    }
    if (!data?.notification) {
      return "Notification";
    }
    if (!data?.disableBatterOptimization) {
      return "DisableBatteryOptimization";
    }
  };

  return (
    <div className="padding-larger display-flex flex-justify-content-center inherit-parent-width inherit-parent-height flex-align-items-center flex-direction-column ">
      {Object.values(values).map(
        (data, index) =>
          Object.keys(values)?.[index] ===
            showPermissionStep(permissionData) && (
            <div
              data-cy={data.dataCy}
              key={index}
              className="display-flex flex-direction-column flex-justify-content-space-between inherit-parent-height"
            >
              <div />

              <div className="display-flex flex-justify-content-center flex-direction-column">
                <img
                  style={
                    Object.keys(values)?.[index] ===
                    "DisableBatteryOptimization"
                      ? { height: "376px", width: "260px" }
                      : {}
                  }
                  draggable={false}
                  src={data.images}
                  alt="any"
                />

                <div className="text-align-center padding-top-larger padding-bottom-large font-size-default font-family-RHD-medium">
                  {data.title}
                </div>

                <div className="text-align-center padding-bottom-large font-size-medium">
                  {data.description}
                </div>
              </div>

              <Button
                className="padding-default"
                onClick={data.onclick}
                text={convertName(Object.keys(values)?.[index])}
              />
            </div>
          )
      )}

      {/* <Button
        className="margin-top-larger"
        variant="transparent"
        onClick={() => {
          clearPermissions();
        }}
        text={"Clear permissions"}
      /> */}
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    app: state.app,
  };
};

const mapDispatchToProps = function () {
  return {
    setPermissionStatus: (data) => setPermissionStatus(data),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Permissions);

const convertName = (key) => {
  if (key === "DisableBatteryOptimization") {
    return "Confirm";
  } else if (key === "Location") {
    return "Confirm";
  } else if (key === "Notification") {
    return "Confirm";
  } else {
    setErrorStatus({ code: "custom", message: "Invalid permission key" });
  }
};
