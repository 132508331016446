import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/action";

export const actionTypes = {
  SET_PERMISSION_STATUS: "SET_PERMISSION_STATUS",
  SET_TRACKING_STATUS: "SET_TRACKING_STATUS",
  CLEAR_PERMISSION_STATUS: "CLEAR_PERMISSION_STATUS",
};

function* setPermissionStatusWorker(action) {
  try {
    yield put({
      type: "SET_PERMISSIONS",
      payload: action.payload,
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

function* clearPermissionStatusWorker(action) {
  try {
    yield put({
      type: "RESET_APP_DATA",
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

function* setTrackingStatusWorker(action) {
  try {
    yield put({
      type: "SET_TRACKING",
      payload: action.payload,
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

export default function* appWatcher() {
  yield all([
    takeEvery("SET_PERMISSION_STATUS", setPermissionStatusWorker),
    takeEvery("SET_TRACKING_STATUS", setTrackingStatusWorker),
    takeEvery("CLEAR_PERMISSION_STATUS", clearPermissionStatusWorker),
  ]);
}

// function* setAppLoading(bool) {
//   yield put({
//     type: "SET_APP_LOADING",
//     payload: {
//       loading: bool,
//     },
//   });
// }
