import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import statusReducer from "./status/reducer";
import profileReducer from "./profile/reducer";
import authReducer from "./authentication/reducer";
import appReducer from "./app/reducer";

const appPersistConfig = {
  key: "app",
  storage: storage,
  blacklist: ["loading"],
};

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["loading", "credentials"],
};

const profilePersistConfig = {
  key: "profile",
  storage: storage,
  blacklist: ["loading"],
};

const statusPersistConfig = {
  key: "status",
  storage: storage,
  blacklist: ["code", "message"],
};

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["auth", "profile", "status"],
};

//root reducer
const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  profile: persistReducer(profilePersistConfig, profileReducer),
  status: persistReducer(statusPersistConfig, statusReducer),
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
