import LogRocket from "logrocket";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import SnackBar from "./Components/SnackBar/SnackBar";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login/Login";
import { useAuthSubscriber } from "./Services/authentication";
import { useProfileListener } from "./Services/database";
import { logrocketInit } from "./Utils/constants";
import Button from "./Components/Button/Button";
import Modal from "./Components/Modal/Modal";
import { logout } from "./Redux/authentication/actions";
import Permissions from "./Screens/Permissions/Permissions";
import { setPermissionStatus, setTrackingStatus } from "./Redux/app/action";
import PermissionAccess from "./Screens/PermissionAccess/PermissionAccess";

function App(props) {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  useAuthSubscriber((isAuth) => {
    setIsAuth(isAuth);
  }, isAuth);

  useProfileListener({
    uid: props.auth.data?.uid,
    phoneNumber: props.auth.data.phoneNumber,
    isAuth: isAuth,
  });

  //for snack bar messages
  useEffect(() => {
    if (props.status.message) {
      setStatus(props.status.message);
    } else {
      setStatus(null);
    }
  }, [props.status]);

  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      const release =
        typeof process.env.REACT_APP_BUILD_NUM === "string"
          ? { release: process.env.REACT_APP_BUILD_NUM }
          : {};
      if (logrocketInit.key) {
        LogRocket.init(logrocketInit.key, release);
      }
    }
  }, []);

  useEffect(() => {
    const data = {
      locationPermissionStatus:
        new URLSearchParams(window.location.search)?.get(
          "location-permission"
        ) || "empty",

      notificationPermissionStatus:
        new URLSearchParams(window.location.search)?.get(
          "notification-permission"
        ) || "empty",

      disableBatteryOptimizationStatus:
        new URLSearchParams(window.location.search)?.get(
          "disable-batteryOptimization"
        ) || "empty",

      trackingStatus:
        typeof new URLSearchParams(window.location.search)?.get(
          "tracking-enabled"
        ) === "string"
          ? new URLSearchParams(window.location.search)?.get(
              "tracking-enabled"
            ) === "true"
            ? true
            : false
          : "empty",
    };
    let _status = {
      location: false,
      notification: false,
      disableBatterOptimization: false,
    };
    if (data.locationPermissionStatus === "approved") {
      _status.location = true;
    }

    if (data.locationPermissionStatus === "disapproved") {
      _status.location = false;
    }

    if (data.notificationPermissionStatus === "approved") {
      _status.notification = true;
    }

    if (data.notificationPermissionStatus === "disapproved") {
      _status.notification = false;
    }

    if (data.disableBatteryOptimizationStatus === "disabled") {
      _status.disableBatterOptimization = true;
    }

    if (data.disableBatteryOptimizationStatus === "enabled") {
      _status.disableBatterOptimization = false;
    }

    if (data.trackingStatus !== "empty") {
      setTrackingStatus(data.trackingStatus);
    }

    if (
      window.location.pathname.includes("permission") &&
      window.location.pathname !== "/"
    ) {
      setPermissionStatus(_status);
    }

    // eslint-disable-next-line
  }, [window.location]);

  console.log(props.app, "app", window.location.pathname);

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute
              uid={props.auth.data?.uid}
              profileData={props.profile.data}
              permissionData={props.app.data?.permissions}
            >
              <Login navigate={navigate} />
            </PublicRoute>
          }
        />

        <Route
          path="/"
          element={
            <ProtectedRoute
              uid={props.auth.data?.uid}
              profileData={props.profile.data}
            >
              <Home navigate={navigate} setLogoutModal={setLogoutModal} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/permission"
          element={
            <ProtectedRoute
              uid={props.auth.data?.uid}
              profileData={props.profile.data}
            >
              <Permissions navigate={navigate} />
            </ProtectedRoute>
          }
        />

        <Route
          path="/locationAccess"
          element={
            <ProtectedRoute
              uid={props.auth.data?.uid}
              profileData={props.profile.data}
            >
              <PermissionAccess
                navigate={navigate}
                setLogoutModal={setLogoutModal}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/internetAccess"
          element={
            <ProtectedRoute
              uid={props.auth.data?.uid}
              profileData={props.profile.data}
            >
              <PermissionAccess
                navigate={navigate}
                setLogoutModal={setLogoutModal}
              />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <SnackBar
        message={status}
        status={props.status}
        type={props.status.code === null ? "success" : "error"}
      />

      <Modal
        show={logoutModal}
        canIgnore={true}
        onClose={() => {
          setLogoutModal(!logoutModal);
        }}
        width="inherit-parent-width"
        position=" absolute-center-self-horizontally bottom-0 "
        maxWidth="max-width-630px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <section className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-larger background-white">
          <div className=" font-family-RHD-medium font-size-medium">LOGOUT</div>
          <div className=" padding-top-default">
            Are you sure to logout from the application?
          </div>

          <section className="display-flex padding-top-larger ">
            <Button
              boxShadow={false}
              type="submit"
              variant="danger"
              text="Yes"
              className={"border-1px-e5e5e5 margin-right-small"}
              onClick={() => {
                props.logout();
                setLogoutModal(false);
              }}
              data-cy={"confirm-logout-button"}
            />
            <Button
              boxShadow={false}
              text="No"
              variant={"secondary"}
              onClick={() => {
                setLogoutModal(false);
              }}
              className={
                "font-color-secondary border-1px-e5e5e5 margin-left-small"
              }
              data-cy={"cancel-logout-button"}
            />
          </section>
        </section>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    status: state.status,
    auth: state.auth,
    app: state.app,
    profile: state.profile,
  };
};

const mapDispatchToProps = function () {
  return {
    logout: () => logout(),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

const ProtectedRoute = ({ uid, children, profileData }) => {
  if (uid === null && profileData === null) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};

const PublicRoute = ({ uid, children, profileData, permissionData }) => {
  if (uid === null && profileData === null) {
    return children;
  } else {
    if (
      permissionData?.location &&
      permissionData?.notification &&
      permissionData?.disableBatterOptimization
    ) {
      return (
        <Navigate to="/permission?location-permission=approved&notification-permission=approved&disable-batteryOptimization=disabled" />
      );
    } else {
      return <Navigate to="/permission" />;
    }
  }
};
