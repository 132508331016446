import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import locationAccess from "../../Assets/locationAccess.svg";
import internetAccess from "../../Assets/noInternetAccess.svg";
import Button from "../../Components/Button/Button";

export default function PermissionAccess(props) {
  const [type, setType] = useState("");

  useEffect(() => {
    if (window.location.pathname.includes("locationAccess")) {
      setType("location");
    } else {
      setType("internet");
    }
    // eslint-disable-next-line
  }, [window.location]);

  return (
    <div
      data-cy={
        type === "location"
          ? "location-access-screen"
          : "internet-access-screen"
      }
      className="inherit-parent-height"
    >
      <Header
        logoutOnClick={() => {
          props.setLogoutModal(true);
        }}
      />
      <div
        style={{ height: "calc(100% - 64px" }}
        className="padding-larger flex-justify-content-center flex-direction-column"
      >
        <div className="display-flex flex-direction-column flex-justify-content-space-between inherit-parent-height">
          <div />
          <div className="display-flex flex-justify-content-center flex-direction-column flex-align-items-center">
            <img
              style={{ height: "64px", width: "64px" }}
              draggable={false}
              src={type === "location" ? locationAccess : internetAccess}
              alt="any"
            />

            <div className="text-align-center padding-top-larger padding-bottom-large font-size-default font-family-RHD-medium">
              {type === "location" ? "Enable location" : "Enable internet"}
            </div>

            <div className="text-align-center padding-bottom-large font-size-medium">
              {type === "location"
                ? "It seems like you have turned off your location Please enable for better performance"
                : "It seems like you have lost the internet connection Please reconnect for better performance"}
            </div>
          </div>
          <Button
            className="padding-default"
            onClick={() => {
              if (type === "location") {
                window.location.href =
                  "gzero://tracking-permission?location-enable=true";
              } else {
                window.location.href =
                  "gzero://tracking-permission?internet-enable=true";
              }
            }}
            text={type === "location" ? "Enable" : "Reconnect"}
          />
        </div>
      </div>
    </div>
  );
}
