import Button from "../../Components/Button/Button";
import Header from "../../Components/Header/Header";
import { connect } from "react-redux";

function Home(props) {
  return (
    <div className="inherit-parent-height" data-cy="home-screen">
      <Header
        logoutOnClick={() => {
          props.setLogoutModal(true);
        }}
      />
      <div className="remaining-body-height_-128px flex-justify-content-center flex-direction-column">
        {!props.app.data?.isTrackingEnabled && (
          <div className="padding-larger">
            <Button
              type="button"
              className=""
              text="START"
              onClick={() => {
                window.location.href = `gzero://tracking-permission?enableTracking=true&refreshToken=${props.auth.data.refreshToken}&APIKey=${process.env.REACT_APP_API_KEY}`;
              }}
              boxShadow={false}
            />
          </div>
        )}

        {props.app.data?.isTrackingEnabled === true && (
          <div className="padding-larger">
            <Button
              type="button"
              className=""
              text="STOP"
              onClick={() => {
                window.location.href =
                  "gzero://tracking-permission?enableTracking=false";
              }}
              boxShadow={false}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    app: state.app,
    auth: state.auth,
  };
};

const mapDispatchToProps = function () {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
