import { useEffect, useState } from "react";
import { query, collection, where, onSnapshot } from "firebase/firestore";
import { database } from "./firebase";
import { setErrorStatus } from "../Redux/status/action";
import { setAccountProfiles } from "../Redux/profile/action";

export function useProfileListener(props) {
  const [profileListener, setProfileListener] = useState({
    listener: null,
  });

  const subscribeToProfile = (phoneNumber) => {
    if (phoneNumber) {
      const profileQuery = query(
        collection(database, "employees"),
        where("phoneNumber", "==", phoneNumber)
      );

      return onSnapshot(
        profileQuery,
        (dataSnapshot) => {
          let profiles = {};
          dataSnapshot.forEach((doc) => {
            profiles = {
              ...doc.data(),
            };
          });
          setAccountProfiles(profiles);
        },
        (error) => {
          console.error(error, "from profile");
          setErrorStatus(error);
        }
      );
    } else {
      return;
    }
  };
  useEffect(() => {
    if (
      props.isAuth === true &&
      typeof props.phoneNumber === "string" &&
      typeof props.uid === "string" &&
      profileListener.listener === null
    ) {
      setProfileListener({
        listener: subscribeToProfile(props.phoneNumber),
      });
    } else if (
      props.isAuth === false &&
      typeof profileListener.listener === "function"
    ) {
      profileListener.listener();
      setProfileListener({
        listener: null,
      });
    }
    // eslint-disable-next-line
  }, [props.isAuth, props.phoneNumber, props.uid]);
}
