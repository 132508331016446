import { put, takeEvery, all } from "redux-saga/effects";
import {
  otpValidate,
  logout,
  otpRequest,
  signInWithAuthCredentials,
} from "../../Services/authentication";
import { throwError } from "../../Services/error";
import { setErrorStatus } from "../status/action";
import { checkAccountAvailability } from "../../Services/api";
import store from "../store";
import { login } from "./actions";

export const authActionTypes = {
  SEND_OTP: "SEND_OTP",
  VERIFY_OTP: "VERIFY_OTP",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  ADD_ACCESS_TOKEN: "ADD_ACCESS_TOKEN",
  ADD_AUTH_INFO: "ADD_AUTH_INFO",
  CLEAR_CREDENTIAL: "CLEAR_CREDENTIAL",
};

function* loginWorker() {
  try {
    yield setAuthLoading(true);
    const uid = store.getState().auth.data.uid;
    const profile = store.getState().profile.data;
    if (uid !== null && profile !== null) {
      throw throwError("custom", "User already logged in");
    }
    yield signInWithAuthCredentials(
      store.getState().auth.credentials.authCredential
    );
    yield setAuthLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* logoutWorker() {
  try {
    yield setAuthLoading(true);
    yield logout();
    yield put({
      type: "RESET",
    });
  } catch (error) {
    yield setAuthLoading(false);
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* sendOtpWorker(action) {
  try {
    yield setAuthLoading(true);
    if (action.payload.type === "login") {
      const response = yield checkAccountAvailability(
        action.payload.phoneNumber
      );
      if (response.data.profileExists !== true) {
        throw throwError("custom", "User does not exist. Please signup");
      }
    }
    const verificationId = yield otpRequest(action.payload.phoneNumber);

    yield put({
      type: "SET_AUTH_VERIFICATION_ID",
      payload: {
        verificationId: verificationId,
      },
    });

    yield setAuthLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* verifyOtpWorker(action) {
  try {
    yield setAuthLoading(true);
    const authCredential = yield otpValidate(
      action.payload.loginOtp,
      store.getState().auth.credentials.verificationId
    );

    yield put({
      type: "SET_AUTH_CREDENTIALS",
      payload: {
        authCredential: authCredential,
      },
    });
    login();
    yield setAuthLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* clearCredentialWorker() {
  yield put({
    type: "CLEAR_AUTH_CREDENTIALS",
  });
}

function* addAccessTokenWorker(action) {
  try {
    yield put({
      type: "SET_ACCESS_TOKEN",
      payload: {
        accessToken: action.payload.accessToken,
      },
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* addAuthInfoWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_INFO",
      payload: action.payload,
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

export default function* authWatcher() {
  yield all([
    takeEvery("SEND_OTP", sendOtpWorker),
    takeEvery("VERIFY_OTP", verifyOtpWorker),
    takeEvery("LOGIN", loginWorker),
    takeEvery("LOGOUT", logoutWorker),
    takeEvery("ADD_ACCESS_TOKEN", addAccessTokenWorker),
    takeEvery("ADD_AUTH_INFO", addAuthInfoWorker),
    takeEvery("CLEAR_CREDENTIAL", clearCredentialWorker),
  ]);
}

function* setAuthLoading(bool) {
  yield put({
    type: "SET_AUTH_LOADING",
    payload: {
      loading: bool,
    },
  });
}
