import React, { useEffect, useState } from "react";
import InputBox from "../../Components/InputBox/InputBox";
import "./Login.css";
import OTPInputBox from "../../Components/OTPInputBox/OTPInputBox";
import Button from "../../Components/Button/Button";
import { connect } from "react-redux";
import { reCaptchaVerifier } from "../../Services/authentication";
import {
  clearCredentials,
  sendOtp,
  verifyOtp,
} from "../../Redux/authentication/actions";
import { mockAuthVerifyOtp } from "../../Redux/mock/actions";
import chennaiCorporationLogo from "../../Assets/chennaiCorporationLogo.png";

function Login(props) {
  const [loginFormData, setLoginFormData] = useState({
    phoneNumber: "+91",
    OTP: null,
  });

  //invisible recaptcha for authentication
  useEffect(() => {
    window.reCaptchaVerifier = reCaptchaVerifier();
  }, []);

  const setOTPValue = (otp) => {
    setLoginFormData({
      ...loginFormData,
      OTP: otp,
    });
  };

  const loginFormChange = (event) => {
    //To clear auth verification while user trying to change phone number after otp sent
    if (
      event.target.name === "phoneNumber" &&
      props.auth.credentials.verificationId &&
      loginFormData.phoneNumber !== event.target.value
    ) {
      props.clearCredentials();
    }
    if (event.target.name.includes("otp")) {
      return;
    } else {
      setLoginFormData({
        ...loginFormData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const isOtpButtonDisabled = () => {
    if (
      typeof loginFormData.phoneNumber === "string" &&
      loginFormData.phoneNumber.trim().length >= 13
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="inherit-parent-height inherit-parent-width display-flex flex-justify-content-center flex-direction-column">
      <article className="inherit-parent-height" data-cy="login-screen">
        <div className="flex-justify-content-center inherit-parent-height flex-align-items-center flex-direction-column padding-horizontal-larger">
          <div className=" height-64px" />
          <img
            src={chennaiCorporationLogo}
            alt="chennai-corporation-logo"
            width="48px"
          />
          <div className=" padding-top-default text-align-center font-family-RHD-medium">
            Greater Chennai Corporation
          </div>
          <form
            className="inherit-parent-height inherit-parent-width max-width-500px flex-justify-content-space-between flex-direction-column"
            onChange={(event) => {
              loginFormChange(event);
            }}
            onSubmit={(event) => event.preventDefault()}
          >
            <div>
              <div className="padding-top-larger padding-bottom-larger text-align-center font-color-secondary">
                LOGIN
              </div>

              <InputBox
                className="inherit-parent-width border-radius-default margin-bottom-large"
                name="phoneNumber"
                label="Phone Number"
                type="tel"
                autoFocus={true}
                autoComplete="off"
                value={loginFormData.phoneNumber}
                buttonTileId="login-button"
                buttonTile={
                  props.auth.credentials.verificationId !== null
                    ? "Resend OTP"
                    : "Get OTP"
                }
                buttonType={
                  typeof props.auth.credentials.verificationId === "string"
                    ? "button"
                    : "submit"
                }
                onButtonClick={(event) => {
                  event.preventDefault();
                  props.sendOtp(loginFormData.phoneNumber, "login");
                }}
                data-cy="login-screen-phone-number-field"
                required
                suffixButtonDisabled={isOtpButtonDisabled()}
              />
              {props.auth.credentials.verificationId && (
                <OTPInputBox
                  name="otp"
                  setValue={setOTPValue}
                  className="margin-bottom-large"
                  required
                />
              )}
            </div>
            <footer className="padding-top-larger padding-bottom-larger inherit-parent-width max-width-500px">
              <Button
                data-cy={
                  props.auth.loading ? "login-loading-button" : "login-button"
                }
                loading={props.auth.loading}
                type={
                  typeof props.auth.credentials.verificationId === "string"
                    ? "submit"
                    : "button"
                }
                className=""
                text="Login"
                onClick={(event) => {
                  event.preventDefault();
                  if (process.env.REACT_APP_STAGING === "local") {
                    props.mockAuthWithOTP("login");
                  } else {
                    props.verifyOtp(loginFormData.OTP);
                  }
                }}
                disabled={
                  !(
                    typeof loginFormData.OTP === "string" &&
                    loginFormData.OTP.length === 6
                  )
                }
                boxShadow={false}
              />
            </footer>
          </form>
        </div>
        <div id="recaptcha-placeholder"></div>
      </article>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
  };
};

const mapDispatchToProps = function () {
  return {
    verifyOtp: (loginOtp) => verifyOtp(loginOtp),
    sendOtp: (phoneNumber, type) => sendOtp(phoneNumber, type),
    mockAuthWithOTP: (type, otp) => mockAuthVerifyOtp(type, otp),
    clearCredentials: () => clearCredentials(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
