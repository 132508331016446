import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module AppSaga
 */

/**
 *
 * @param {permission} payload permission data
 */
export function setPermissionStatus(permission) {
  store.dispatch({
    type: actionTypes.SET_PERMISSION_STATUS,
    payload: permission,
  });
}

/**
 *
 * @param {status} payload status data
 */
export function setTrackingStatus(status) {
  store.dispatch({
    type: actionTypes.SET_TRACKING_STATUS,
    payload: status,
  });
}

/**
 *
 */
export function clearPermissions() {
  store.dispatch({
    type: actionTypes.CLEAR_PERMISSION_STATUS,
  });
}
