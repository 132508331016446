import { all } from "redux-saga/effects";
import authWatcher from "./authentication/saga";
import profileWatcher from "./profile/saga";
import statusWatcher from "./status/saga";
import mockWatcher from "./mock/saga";
import appWatcher from "./app/saga";

export default function* rootSaga() {
  yield all([
    appWatcher(),
    authWatcher(),
    profileWatcher(),
    statusWatcher(),
    mockWatcher(),
  ]);
}
